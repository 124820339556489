<template>
    <form @submit.prevent="submit" class="form-booking">
        <div class="form-tab">
            <div>
                <div class="form-group">
                    <label>{{ $t('name') }}</label>
                    <input
                        type="text"
                        v-model.trim="contacts.name"
                        required
                        class="form-control mb-1"
                        :placeholder="$t('name') + ' ' + $t('Required')"
                    />
                </div>
        
                <div class="form-group">
                    <label>{{ $t('phone') }}</label>
                    <input
                        type="text"
                        dir="ltr"
                        required
                        v-model.trim="contacts.phone"
                        class="form-control mb-1"
                        :placeholder="$t('Required') + ' +966*********'"
                    />

                </div>

                <div class="form-group">
                    <label>{{ $t('Email') }}</label>
                    <input
                        type="email"
                        dir="ltr"
                        v-model.trim="contacts.email"
                        class="form-control mb-1"
                        :placeholder="$t('Email')"
                    />

                </div>

                <div class="form-group">
                    <label>{{ $t('Subject') }}</label>
                    <input
                        type="text"
                        v-model.trim="contacts.subject"
                        class="form-control mb-1"
                        :placeholder="$t('Subject')"
                    />

                </div>

                <div class="form-group">
                    <label>{{ $t('Message') }}</label>
                    <textarea rows="4" type="text" required v-model.trim="contacts.message" class="form-control mb-1"
                        :placeholder="$t('Message') + ' ' + $t('Required')"></textarea>
                </div>
                        
        
                <div class="form-group">
                    <button id="submit" :disabled="lock" class="btn btn-filter">
                        <template v-if="!lock">
                            <span>{{$t('Submit Now')}}</span>
                        </template>
                        <template v-else>
                            <spanner :loading="lock"></spanner>
                        </template>
                        
                    </button>
                </div>  
        
            </div>
        </div>
    </form>
</template>
<script>
    import moment from 'moment';

export default {
        props : ['routeStore'],
        data(){
            return {
                gelang: window.lang,
                lock : false,
                contacts:{
                    name: "",
                    phone: "",
                    email: "",
                    subject: "",
                    message: ""
                }
            };
        },
       
        methods: {
            submit(){
                this.lock = true;
                if(!this.validateOffer()){
                    this.lock = false;
                    return;
                }
                axios.post(this.routeStore, this.contacts)
                .then((result) => {
                    this.$Notice.success({
                        title: this.$t('Alert'),
                        desc: result.data.message? result.data.message : this.$t('Data Saved Successfully'),
                    });
                    this.resetOrder();
                    $('#sendModal').modal('show');
                    this.lock = false;
                }).catch((err) => {
                    this.$Notice.error({
                        title: this.$t('Alert'),
                        desc: err.response.data.message,
                    });
                    this.lock = false;
                });
            }, 
            
            resetOrder(){
                this.contacts = {
                    name: "",
                    phone: "",
                    email: "",
                    subject: "",
                    message: ""
                };
            },
           
            validateOffer(){
                let rtn = true;
                if(this.contacts.name.trim() == ""){
                    this.$Notice.error({
                        title: this.$t('Name is required'),
                    });
                    rtn = false;
                }
                if(this.contacts.subject == ""){
                    this.$Notice.error({
                        title: this.$t('subject is required'),
                    });
                    rtn = false;
                }
                if(this.contacts.message == ""){
                    this.$Notice.error({
                        title: this.$t('message is required'),
                    });
                    rtn = false;
                }
                if (!(/((\+?9665)|05)([0-9]{7,9})$/.test(this.contacts.phone.trim()))) {
                    this.$Notice.error({
                        title: this.$t(`Phone Format (+9665*******,05********)`),
                    });
                    rtn = false;
                }
                return rtn;
            }
        }
    }
</script>