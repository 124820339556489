
export default {
  "Required":"(*)",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":"",
  "":""
  
  }
  