<template>
    <div class="blog-block">
        <a @click="toggleModal" class="img">
            <img style="width: 80px;height: auto;" :src="item.image ? item.image.path : '/logo.png'" alt="#" />
        </a>
        <div class="details text-center">
            <!-- <span class="date-h ">
               {{ format_time(item.created_at) }}
            </span> -->
            <a @click="toggleModal" class="name text-center">
                {{ item.title[locale] }}    
            </a>
        </div>
        <div class="vue-modal" v-if="openModal">
			<div class="vue-content wide-modal">
                <div class="vue-modal-header">
                    <h2>{{ item.title[locale] }}</h2>
                </div>
				<div class="vue-modal-body">
					
                    <template v-if="item.type == 'airport'">
                        <Airport :store-route="storeRoute" :airports-route="airportsRoute" :category-id="item.id" :cities="cities" />
                    </template>
                    <template v-else-if="item.type == 'hotels'">
                        <Hotels :store-route="storeRoute" :category-id="item.id" :cities="cities" />
                    </template>
                    <template v-else-if="item.type == 'activities'">
                        <Activities :store-route="storeRoute" :category-id="item.id" :cities="cities" />
                    </template>
                    <template v-else-if="item.type == 'chalets'">
                        <Chalets :store-route="storeRoute" :category-id="item.id" :cities="cities" />
                    </template>
                    <template v-else-if="item.type == 'cars'">
                        <Cars :store-route="storeRoute" :category-id="item.id" />
                    </template>
                    <template v-else-if="item.type == 'camping'">
                        <Camping :store-route="storeRoute" :category-id="item.id" :cities="cities" />
                    </template>
                    <template v-else>
                        <Camping :store-route="storeRoute" :category-id="item.id" :cities="cities" />
                        <hr>
                        <div style="min-height: 100px; padding-top: 20px;" v-html="item.detailsLang"></div>
                    </template>
				</div>
				<div class="vue-modal-footer">
					<div class="d-flex justify-content-end">
						<button type="button" @click="toggleModal" class="btn btn-danger">{{ $t('Close') }}</button>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
    import Airport from './../components/reservation/Airport.vue'
    import Hotels from './../components/reservation/Hotels.vue'
    import Activities from './../components/reservation/Activities.vue'
    import Chalets from './../components/reservation/Chalets.vue'
    import Camping from './../components/reservation/Camping.vue';
    import Cars from './../components/reservation/Cars.vue';

export default {
        props: ['item', 'storeRoute', "cityRoute", "airportsRoute"],
        components: {
            Airport,
            Hotels,
            Activities,
            Chalets,
            Cars,
            Camping,
        },
        data(){
            return {
                locale: window.lang,
                openModal: false,
                cities: [],
            }
        },
        beforeMount() {
            this.fetchCities();
        },
        mounted(){
            let _this = this;
            this.$root.$on('openModal', ()=> {
                _this.openModal = false;
            });
        },
        methods: {
            toggleModal(){
                this.openModal = !this.openModal;
                if (this.openModal) {
                    $("body").addClass("modal-open");
                } else {
                    $("body").removeClass("modal-open");
                }
            },
            fetchCities() {
                axios.get(this.cityRoute).then((res) => {
                    this.cities = res.data.cities;
                }).catch((err) => {
                    console.log(err.response.message);
                });
            }
        }
    }
</script>
<style>
    .form .form-group .form-control {
        height: 47px;
    }
    .vue-modal-header{
        padding: 28px 25px 5px;
        border-bottom: 1px solid #ccc;
        text-align: center;
    }
    @media screen and (min-width: 991px) {

        .vue-content.wide-modal{
            width: 970px;
        }
        .vue-modal-body{
            padding: 25px;
        }
        .vue-modal-header{
            padding: 28px 25px 5px;
            border-bottom: 1px solid #ccc;
            text-align: inherit;
        }
    }
</style>