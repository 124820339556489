<template>
	<div>
		<form @submit.prevent="sendOrder" method="POST" class="row form">
			<!-- Col -->
			<div class="col-md-10">
				<div class="row">
					<!-- Col -->
					<div class="col-md-4">
						<div class="form-group">
							<input type="text" v-model.trim="order.name" required class="form-control mb-1"
								:placeholder="$t('name') + ' ' + $t('Required')" />

							<input type="text" dir="ltr" required v-model.trim="order.phone" class="form-control mb-1"
								:placeholder="'+966********* ' + $t('Required')" />

							<button @click="toggleModal" type="button" class="btn btn-light w-100 toggle">
								{{ $t('Passengers Number') }} ({{ adults }})
							</button>
						</div>
					</div>
					<!-- /Col -->

					<!-- Col -->
					<div class="col-md-4">
						<div class="form-group">
							<v-select required :options="flights" :reduce="(item) => item.id"
								:placeholder="$t('Flight') + ' ' + $t('Required')" v-model.trim="order.flight"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" label="titleLang" class="mb-1">
							</v-select>

							<v-select required @search="fetchAirports1"  :options="airports1" :reduce="(airport) => airport.id"
								:placeholder="$t('Start Location') + ' ' + $t('Required')" v-model="order.from_id"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" class="mb-1" label="titleLang">
							
								<template v-slot:option="option">
									<div class="d-flex align-items-center" style="gap: 8px;">
										<i class="fa fa-plane"></i>
										<div>
											<p>{{ option.city.titleLang }}</p>
											<p class="text-muted">{{ option.titleLang }}</p>
										</div>
									</div>
								  </template>
								  <template slot="selected-option" slot-scope="option">
									<div class="d-flex align-items-center" style="gap: 8px;">
										<i class="fa fa-plane"></i>
										<p class="text-muted" style="max-width: 100%; overflow-x: hidden;">
											<em>{{ option.titleLang }}</em>
										</p>
									</div>
								  </template>
								  <template slot="no-options">
									{{ $t('Start typing to search') }}
								  </template>
							</v-select>

							<v-select required @search="fetchAirports2" :options="airports2" :reduce="(airport) => airport.id"
								:placeholder="$t('Destination') + ' ' + $t('Required')" v-model="order.to_id"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" label="titleLang">
							
								<template v-slot:option="option">
									<div class="d-flex align-items-center" style="gap: 8px;">
										<i class="fa fa-plane"></i>
										<div>
											<p>{{ option.city.titleLang }}</p>
											<p class="text-muted">{{ option.titleLang }}</p>
										</div>
									</div>
								  </template>
								  <template slot="selected-option" slot-scope="option">
									<div class="d-flex align-items-center" style="gap: 8px;">
										<i class="fa fa-plane"></i>
										<p class="text-muted" style="max-width: 100%; overflow-x: hidden;">
											<em>{{ option.titleLang }}</em>
										</p>
									</div>
								  </template>
								  <template slot="no-options">
									{{ $t('Start typing to search') }}
								  </template>
							</v-select>
						</div>
					</div>
					<!-- /Col -->

					<!-- Col -->
					<div class="col-md-4">
						<div class="form-check form-switch">
							<input class="form-check-input" value="1" v-model.trim="isRound" type="checkbox"
								role="switch" id="flexSwitchCheckDefault">
							<label class="form-check-label" for="flexSwitchCheckDefault">{{ $t('Round-trip') }}</label>
						</div>
						<div class="form-group">
							<DatePicker :editable="false" format="YYYY-MM-DD" :disabledDate="disabledDates"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" v-model.trim="order.start_at" placeholder="yyyy-mm-dd" value-type="format"
								class="mb-1" required>
							</DatePicker>
							<template v-if="isRound">
								<DatePicker :editable="false" format="YYYY-MM-DD" :required="isRound"
									:disabledDate="disabledAfterDates" :dir="gelang == 'en' ? 'ltr' : 'rtl'"
									v-model.trim="order.back_at" placeholder="yyyy-mm-dd" value-type="format"></DatePicker>
							</template>
						</div>
					</div>
					<!-- /Col -->
				</div>
			</div>
			<!-- /Col -->
			<!-- Col -->
			<div class="col-md-2">
				<button type="submit" :disabled="lock" class="btn btn-filter">
					<template v-if="!lock">
						<span>
							{{ $t("Send") }}
							<!-- <br />
							{{ $t("Request") }} -->
						</span>
					</template>
					<template v-else>
						<spanner :loading="lock"></spanner>
					</template>
				</button>
			</div>
			<!-- /Col -->
		</form>

		<div class="vue-modal" v-if="showModal && !lock">
			<div class="vue-content">
				<div class="vue-modal-body">
					<div class="form-group">
						<label for="">{{ $t("Passengers") }}</label>
						<input type="number" dir="ltr" v-model.trim="order.adult" class="form-control" min="1"
							:placeholder="$t('adults')" />
					</div>
					<div class="form-group">
						<label for="">{{ $t("Childs") }}</label>

						<input type="number" dir="ltr" v-model.trim="order.childs" class="form-control" min="0"
							:placeholder="$t('Childs')" />
					</div>
					<div class="form-group">
						<label for="">{{ $t("Infants") }}</label>
						<input type="number" dir="ltr" v-model.trim="order.infants" min="0" class="form-control"
							:placeholder="$t('Infants')" />
					</div>
				</div>
				<div class="vue-modal-footer">
					<div class="d-flex justify-content-between">
						<button type="button" @click="toggleModal" class="btn btn-success">{{ $t('Save') }}</button>
						<button type="button" @click="toggleModal" class="btn btn-danger">{{ $t('Close') }}</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: ["categoryId", "cities", 'storeRoute', "airportsRoute"],
	data() {
		return {
			gelang: window.lang,
			showModal: false,
			isRound: false,
			lock: false,
			airports1: [],
			airports2: [],
			flights: [
				{ id: "economy", titleLang: this.$t("Economy") },
				{ id: "business", titleLang: this.$t("Business") },
			],
			order: {
				name: "",
				phone: "",
				from_id: "",
				to_id: "",
				start_at: "",
				back_at: null,
				category_id: "",
				infants: 0,
				childs: 0,
				adult: 1,
				flight: "",
				type: 'airport'
			},
		};
	},
	computed: {
		adults() {
			return parseInt(this.order.adult) + parseInt(this.order.childs) + parseInt(this.order.infants);
		}
	},
	watch: {
		isRound() {
			if (!this.isRound) {
				this.order.back_at = null;
			}else{
				this.order.back_at = moment(this.order.start_at).add(10, 'days').format("YYYY-MM-DD");
			}
		}
	},
	beforeMount() {
		this.order.category_id = this.categoryId;
	},
	methods: {
		fetchAirports1 (search, loading) {
			if(search.length) {
				loading(true);
				this.searchAirports(loading, search, this, 'air1');
			}
		},
		fetchAirports2 (search, loading) {
			if(search.length) {
				loading(true);
				this.searchAirports(loading, search, this, 'air2');
			}
		},
		searchAirports: _.debounce((loading, search, vm, opt) => {
			axios.get(vm.airportsRoute, {params: {
				search: search,
				execlude: opt == 'air1' ? vm.order.to_id : vm.order.from_id,
			}}).then((res) => {
				if(opt == 'air1'){
					vm.order.from_id = null;
					vm.airports1 = res.data.airports;
				}else{
					vm.order.to_id = null;
					vm.airports2 = res.data.airports;
				}
				loading(false);
			});
		}, 600),
		sendOrder() {
			this.lock = true;
			if (!this.validateBeforeSubmit()) {
				this.lock = false;
				return;
			}
			if(!this.isRound){
				this.order.back_at = null;
			}

			axios.post(this.storeRoute, this.order).then((res) => {
				this.$Notice.success({
					title: this.$t('Alert'),
					desc: this.$t('Data Saved Successfully')
				});
				this.resetOrder();
				this.lock = false;
				this.$root.$emit('openModal');
			}).catch((err) => {
				this.$Notice.error({
					title: this.$t('Alert'),
					desc: err.response.data.message,
				});
				this.lock = false;
			});

		},
		resetOrder() {
			this.order = {
				name: "",
				phone: "",
				from_id: "",
				to_id: "",
				start_at: "",
				back_at: "",
				category_id: "",
				infants: 0,
				childs: 0,
				adult: 1,
				flight: "",
				type: 'airport'
			};
		},
		disabledDates(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return date < today;
		},
		disabledAfterDates(date) {
			const today = new Date(this.order.start_at) || new Date();
			today.setHours(0, 0, 0, 0);
			return date < today;
		},
		toggleModal() {
			this.showModal = !this.showModal;
			if (this.showModal && !this.lock) {
				$("body").addClass("modal-open");
			} else {
				$("body").removeClass("modal-open");
			}
		},

		validateBeforeSubmit() {
			let rtn = true;
			if (this.order.name.trim() == "") {
				this.$Notice.error({
					title: this.$t('Name is required'),
				});
				rtn = false;
			}
			if (this.order.start_at == null || this.order.start_at.trim() == "") {
				this.$Notice.error({
					title: this.$t('travel date is required'),
				});
				rtn = false;
			}
			if (this.order.infants < 0) {
				this.$Notice.error({
					title: this.$t('Infants Must be >= 0'),
				});
				rtn = false;
			}
			if (this.order.childs < 0) {
				this.$Notice.error({
					title: this.$t('Childs Must be >= 0'),
				});
				rtn = false;
			}
			if (this.order.adult < 0) {
				this.$Notice.error({
					title: this.$t('adult Must be >= 1'),
				});
				rtn = false;
			}
			if (isNaN(this.order.phone)) {
				this.$Notice.error({
					title: this.$t('Phone shoud be number'),
				});
				rtn = false;
			}
			if (!(/((\+?966)|05)([0-9]{7,9})$/.test(this.order.phone.trim()))) {
				this.$Notice.error({
					title: this.$t(`Phone Format (+9665*******,05********)`),
				});
				rtn = false;
			}
			if (this.order.from_id == null || isNaN(this.order.from_id)) {
				this.$Notice.error({
					title: this.$t('Start Location is required'),
				});
				rtn = false;
			}
			if (this.order.to_id == null || isNaN(this.order.to_id)) {
				this.$Notice.error({
					title: this.$t('Destination is required'),
				});
				rtn = false;
			}

			if (this.order.flight.trim() == "") {
				this.$Notice.error({
					title: this.$t('Flight is required'),
				});
				rtn = false;
			}

			return rtn;
		}
	},
};
</script>