<template>
<div>
    <form @submit.prevent="submit" class="form-booking">
        <div class="form-tab">
            <div>
                <div class="form-group">
                    <label>{{ $t('name') }}</label>
                    <input
                        type="text"
                        v-model.trim="order.name"
                        required
                        class="form-control mb-1"
                        :placeholder="$t('name') + ' ' + $t('Required')"
                    />
                </div>

                <div class="form-group">
                    <label>{{ $t('Email') }}</label>
                    <input
                        type="text"
                        v-model.trim="order.email"
                        required
                        class="form-control mb-1"
                        :placeholder="$t('Email')"
                    />
                </div>
        
                <div class="form-group">
                    <label>{{ $t('phone') }}</label>
                    <input
                        type="text"
                        dir="ltr"
                        required
                        v-model.trim="order.phone"
                        class="form-control mb-1"
                        :placeholder="$t('Required') + ' +9665*********'"
                    />

                </div>
        
                <div class="form-group">
                    <label>{{ $t('Expected Travel Date') }}</label>
                    <DatePicker :editable="false"
                        format="YYYY-MM-DD"
                        :disabledDate="disabledDates"
                        :dir="gelang == 'en' ? 'ltr' : 'rtl'"
                        v-model.trim="order.start_at" placeholder="yyyy-mm-dd"
                        value-type="format"
                        class="mb-1"
                        required
                    ></DatePicker>
                </div>
                
                <div class="form-group mb-3">
                    <label for="">{{ $t("Number of individuals") }}</label>
                    <button @click="toggleModal" type="button" class="btn btn-light w-100 toggle">
                        {{ $t("Number of individuals") }} ({{ adults }})
                    </button>
                </div>
        
                <div class="form-group">
                    <button id="submit" :disabled="lock" class="btn btn-filter">
                        <template v-if="!lock">
                            <span>{{$t('Submit Now')}}</span>
                        </template>
                        <template v-else>
                            <spanner :loading="lock"></spanner>
                        </template>
                    </button>
                </div>  
        
            </div>
        </div>
    </form>
    <div class="vue-modal" v-if="showModal && !lock">
        <div class="vue-content">
            <div class="vue-modal-body">
                <div class="form-group">
                    <label for="">{{ $t("Passengers") }}</label>
                    <input type="number" dir="ltr" v-model.trim="order.adult" class="form-control" min="1"
                        :placeholder="$t('adults')" />
                </div>
                <div class="form-group">
                    <label for="">{{ $t("Childs") }}</label>

                    <input type="number" dir="ltr" v-model.trim="order.childs" class="form-control" min="0"
                        :placeholder="$t('Childs')" />
                </div>
            </div>
            <div class="vue-modal-footer">
                <div class="d-flex justify-content-between">
                    <button type="button" @click="toggleModal" class="btn btn-success">
                        {{ $t("Save") }}
                    </button>
                    <button type="button" @click="toggleModal" class="btn btn-danger">
                        {{ $t("Close") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
        props: ['routeStore', 'offerId'],
        data(){
            return {
                showModal: false,
                lock: false,
                order:{
                    name: "",
                    phone: "",
                    offer_id:"",
                    start_at: "",
                    email: "",
                    adult: 1,
                    childs: 0,
                    type: "offers",
                }
            };
        },
        computed: {
            adults() {
                return parseInt(this.order.adult) + parseInt(this.order.childs);
            },
        },
        beforeMount() {
            this.order.offer_id = this.offerId;
        },
        methods: {
            submit(){
                this.lock = true;
                if(!this.validateOffer()){
                    this.lock = false;
                    return;
                }
                axios.post(this.routeStore, this.order)
                .then((result) => {
                    this.$Notice.success({
                        title: this.$t('Alert'),
                        desc: result.data.message? result.data.message : this.$t('Data Saved Successfully'),
                    });
                    this.resetOrder();
                    $('#sendModal').modal('show');
                    this.lock = false;
                }).catch((err) => {
                    this.$Notice.error({
                        title: this.$t('Alert'),
                        desc: err.response.data.message,
                    });
                    this.lock = false;
                });
            }, 
            resetOrder(){
                this.order = {
                    name: "",
                    phone: "",
                    offer_id:"",
                    start_at: "",
                    type: "offers",
                };
            },
            disabledDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            toggleModal() {
                this.showModal = !this.showModal;
                if(!this.showModal && (this.order.adult < 1 || this.order.childs < 0)){
                    this.showModal = !this.showModal;
                    return;
                }
                if (this.showModal && !this.lock) {
                    $("body").addClass("modal-open");
                } else {
                    $("body").removeClass("modal-open");
                }
            },
            validateOffer(){
                let rtn = true;
                if(this.order.name.trim() == ""){
                    this.$Notice.error({
                        title: this.$t('Name is required'),
                    });
                    rtn = false;
                }
                if (this.order.start_at == null || this.order.start_at.trim() == "") {
                    this.$Notice.error({
                        title: this.$t('travel date is required'),
                    });
                    rtn = false;
                }
                if (!(/((\+?966)|05)([0-9]{7,9})$/.test(this.order.phone.trim()))) {
                    this.$Notice.error({
                        title: this.$t(`Phone Format (+9665*******,05********)`),
                    });
                    rtn = false;
                }
                return rtn;
            }
        }
    }   
</script>