<template>
    <form @submit.prevent="submit" method="POST">
        <input type="email" v-model.trim="email" placeholder="ENTER EMAIL" />
        <button type="submit">{{ $t('SUBSCRIB') }}</button>
    </form>
</template>
<script>
    import moment from 'moment';

export default {
        props: ['routeStore'],
        data(){
            return {
                email: ""
            };
        },
        methods: {
            submit(){
                if(this.email.trim() == ""){
                    this.$Notice.error({
                        title: this.$t("Email is Required"),
                        top: 'auto'
                    });
                    return;
                }
                axios.post(this.routeStore, {email:this.email})
                .then((result) => {
                    this.$Notice.success({
                        title: this.$t('Alert'),
                        desc: result.data.message,
                        top: 'auto'
                    });
                    this.email = ""
                }).catch((err) => {
                    this.$Notice.error({
                        title: this.$t('Alert'),
                        desc: err.response.data.message,
                        top: 'auto'
                    });
                });
            }
        }
    }   
</script>