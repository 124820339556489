import moment from "moment";

const methods = {

    format_time(date=null, format="DD MMM yyyy", def= true){
        if(date)
            return moment(date).format(format)
        if(def)
            return moment().format(format);
        return "--------"
    }, 

    str_limit(str="", limit= 12){
        if(str && str.length > limit){
            return str.substring(0, limit);
        }
        return str;
    },

    optional(obj, key, def='--------'){
        if(obj && obj.hasOwnProperty(key) && obj[key]){
            return obj[key];
        }
        return def
    },
    format_number(num , precision = 2){
        if(num == null || num == undefined){
            num = 0;
            return num.toFixed(precision);
        }
        if(isNaN(num)){
            return num;
        }
        return num.toFixed(precision);
    },

    getFontClass(words = 0){
        if(words <= 18){
            return 'fs-25';
        }else {
            return  'fs-14';
        }
    },
    get_words(str, words=10){
        str = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(words > 0){
            return str.split(/\s+/).slice(0, words).join(" ");
        }
        return str;
    },
    
};

export default methods;
