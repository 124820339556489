window._ = require('lodash');

import axios from 'axios';
window.axios = axios;


let gelang = document.documentElement.lang;

window.lang = gelang ? gelang : "en";

window.axios.defaults.headers.common['lang'] = lang ? lang : "en";
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').content;


import methods from './helper.js';
import Vue from 'vue';
Vue.mixin({methods: methods});

import i18n from './i18n/i18n'

import ViewUI from 'view-design';
Vue.use(ViewUI, { /* options */ });


import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import DatePicker from 'vue2-datepicker';
Vue.component("DatePicker", DatePicker);
Vue.component('spanner', require('vue-spinner/src/PulseLoader.vue').default);

Vue.component('Reservation', require('./pages/Reservation.vue').default);
Vue.component('Subscribe', require('./pages/Subscribe.vue').default);
Vue.component('Offer', require('./pages/Offer.vue').default);
Vue.component('Service', require('./pages/Service.vue').default);
Vue.component('Register', require('./pages/Register.vue').default);
Vue.component('Contact', require('./pages/Contact.vue').default);



const app = new Vue({
    el: '#app',
    i18n
});