<template>
    <div>
        <template v-if="type == 'airport'">
            <Airport :store-route="storeRoute" :airports-route="airportsRoute" :category-id="categoryId" :cities="cities" />
        </template>
        <template v-else-if="type == 'hotels'">
            <Hotels :store-route="storeRoute" :category-id="categoryId" :cities="cities" />
        </template>
        <template v-else-if="type == 'activities'">
            <Activities :store-route="storeRoute" :category-id="categoryId" :cities="cities" />
        </template>
        <template v-else-if="type == 'chalets'">
            <Chalets :store-route="storeRoute" :category-id="categoryId" :cities="cities" />
        </template>
        <template v-else-if="type == 'cars'">
            <Cars :store-route="storeRoute" :category-id="categoryId"  />
        </template>
        <template v-else-if="type == 'camping'">
            <Camping :store-route="storeRoute" :category-id="categoryId" :cities="cities" />
        </template>
        <template v-else>
            <Camping :store-route="storeRoute" :category-id="categoryId" :cities="cities" />
            <hr>
            <div style="min-height: 200px; padding-top: 20px;" v-html="categoryMsg"></div>
        </template>
    </div>
</template>

<script>
    import Airport from './Airport.vue'
    import Hotels from './Hotels.vue'
    import Activities from './Activities.vue'
    import Chalets from './Chalets.vue'
    import Camping from './Camping.vue';
    import Cars from './Cars.vue';
    

    import moment from 'moment';

export default {
        props:['categoryId', 'type', 'storeRoute', 'categoryMsg', 'cities', "airportsRoute"],
        components: {
            Airport,
            Hotels,
            Activities,
            Chalets,
            Cars,
            Camping,
        }
    }
</script>