
<template>
    <div class="banner-filter">
        <template v-if="loading">
            <div class="tab-content d-flex justify-content-center align-items-center spanner-loader">
                <spanner :loading="loading"></spanner>
            </div>
        </template>
        <template v-else>
            <ul class="nav nav-tabs">
                <li class="nav-item" v-for="(category, index) in categories" :key="category.id">
                    <button class="nav-link" :class="index == 0 ? 'active' : ''" data-bs-toggle="tab"
                        :data-bs-target="`#filter-${index}`">
                        <div class="icon">
                            <img :src="category.image ? category.image.path : '/site/images/filter-5.png'" alt="category image" />
                        </div>
                        <span>{{ category.titleLang }}</span>
                    </button>
                </li>
                <li class="nav-item">
                    <a :href="routeOffer" class="nav-link">
                        <div class="icon">
                            <img src="/site/images/offer.png" alt="offer image" />
                        </div>
                        <span>{{ $t('Special Offers') }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a :href="routeMore" class="nav-link">
                        <div class="icon">
                            <img src="/site/images/filter-5.png" alt="category image" />
                        </div>
                        <span>{{ $t('More') }}</span>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div v-for="(category, index) in categories" :key="category.id" class="tab-pane fade"
                    :class="index == 0 ? 'show active' : ''" :id="`filter-${index}`">
                    <div class="form-tab">
                        <Form :type="category.type" :store-route="storeRoute" :category-id="category.id"
                            :category-msg="category.detailsLang" :cities="cities" :airports-route="airportsRoute" />
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>
<script>
    import Form from './../components/reservation/Form.vue';

export default {
        props: ['categoryRoute', 'storeRoute', 'routeMore', 'routeOffer', 'cityRoute', "airportsRoute"],
        components: {
            Form,
        },
        data() {
            return {
                cities: [],
                categories: [],
                loading: true,
            };
        },
        beforeMount() {
            this.fetchCities();
            this.fetchCategories();
        },

        methods: {
            fetchCategories() {
                axios.get(this.categoryRoute).then((res) => {
                    this.categories = res.data.categories;
                    setTimeout(() => { this.loading = false }, 600);
                }).catch((err) => {
                    console.log(err.response.message);
                    setTimeout(() => { this.loading = false }, 600);
                });
            },
            fetchCities() {
                axios.get(this.cityRoute).then((res) => {
                    this.cities = res.data.cities;
                }).catch((err) => {
                    console.log(err.response.message);
                });
            }
        },
    };
</script>