export default {
  "name":"الإسم",
  "phone":"رقم الهاتف",
  "destination":"الوجهة",
  "duration":"مدة الرحلة",
  "Send":"إرسال",
  "Request":"الطلب",
  "Destination":"محطة الوصول",
  "Start Location":"محطة المغادرة",
  "Travel Date":"تاريخ السفر",
  "Return Date":"تاريخ العودة",
  "Passengers":"عدد المسافرين (+12 سنة)",
  "Childs":"الاطفال (2-11 سنة)",
  "Infants":"الرضع (تحت سنتين)",
  "Passengers Number":"عدد المسافرين",
  "Flight":"نوع الرحلة",
  "Economy":"إقتصادية",
  "Business":"أعمال",
  "First":"أولي",
  "Round-trip":"رحلة ذهاب وعودة",
  "Save":"حفظ",
  "Close":"اغلاق",
  "Alert":"إشعـــــار",
  "All feilds are required":"جميع الحقول مطلوبه!!",
  "Data Saved Successfully":"شكرا سيتم التواصل معك قريبا!",
  "Someting Wrong, Try Again":"حدث خظا ما يرجي المحاوله مره اخري",
  "Number of individuals":"عدد الافراد",
  "Date of residence":"بداية الحجز",
  "Date of checkout":"تاريخ المغادره",
  "the city":"المدينة",
  "Activity":"ما هو النشاط؟",
  "SUBSCRIB":"اشتراك",
  "Submit Now":"ارسل",
  "Expected Travel Date":"تاريخ السفر المتوقع",
  "Chalets":"شاليهات",
  "Car Type":"نوع السيارة",
  "From Date":"من تاريخ",
  "To Date":"الي تاريخ",
  "Email":"البريد الالكتروني(اختياري)",
  "More":"المزيد",
  "Message":"اكتب رسالتك",
  "Subject":"عنوان الرسالة",
  "Special Offers":"عروضنا المميزه",
  "city/country":"الدولة / المدينة",
  "travel Date":"تاريخ السفر (ذهاب - إياب)",
  "Required":"(*)",
  "No results found for":"لا يوجد نتائج بحث عن ",
  "Start typing to search":"ابدء في الكتابه للبحث",
  "":"",
  "":"",
  "":"",
  "":"",
  "":""
  }
  