<template>
    <Form :route-store="routeStore" :offer-id="offerId" />
</template>
<script>
import Form from '../components/offers/Form.vue';
import moment from 'moment';

export default {
    props: ['routeStore', 'offerId'],
    components: {
        Form
    }
}
</script>