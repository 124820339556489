<template>
    <form @submit.prevent="submit" class="form-booking">
        <div class="form-tab">
            <div>
                <div class="form-group">
                    <label>{{ $t('name') }}</label>
                    <input
                        type="text"
                        v-model.trim="order.name"
                        required
                        class="form-control mb-1"
                        :placeholder="$t('name') + ' ' + $t('Required')"
                    />
                </div>
        
                <div class="form-group">
                    <label>{{ $t('phone') }}</label>
                    <input
                        type="text"
                        dir="ltr"
                        required
                        v-model.trim="order.phone"
                        class="form-control mb-1"
                        :placeholder="$t('Required') + ' +966*********'"
                    />

                </div>

                <div class="form-group">
                    <label>{{ $t('city/country') }}</label>
                    <input type="text" required v-model.trim="order.city_id" class="form-control mb-1"
                        :placeholder="$t('city/country') + ' ' + $t('Required')" />
                </div>
        
                <div class="form-group">
                    <label>{{ $t('travel Date') }}</label>
                    <DatePicker :editable="false"
                        format="YYYY-MM-DD"
                        :disabledDate="disabledDates"
                        :dir="gelang == 'en' ? 'ltr' : 'rtl'"
                        v-model.trim="order.date"
                        value-type="format"
                        class="mb-1"
                        required
                    ></DatePicker>
                </div>

                <div class="form-group">
                    <label>{{ $t('Email') }}</label>
                    <input
                        type="email"
                        dir="ltr"
                        v-model.trim="order.email"
                        class="form-control mb-1"
                        :placeholder="$t('Email')"
                    />

                </div>
        
                <div class="form-group">
                    <button id="submit" :disabled="lock" class="btn btn-filter">
                        <template v-if="!lock">
                            <span>{{$t('Submit Now')}}</span>
                        </template>
                        <template v-else>
                            <spanner :loading="lock"></spanner>
                        </template>
                    </button>
                </div>  
        
            </div>
        </div>
    </form>
</template>
<script>
    import moment from 'moment';

export default {
        props : ['routeStore'],
        data(){
            return {
                gelang: window.lang,
                lock:false,
                order:{
                    name: "",
                    phone: "",
                    email: "",
                    city_id: "",
                    date: moment().format('YYYY-MM-DD'),
                }
            };
        },
       
        methods: {
            submit(){
                this.lock = true;
                if(!this.validateOffer()){
                    this.lock = false;
                    return;
                }
                axios.post(this.routeStore, this.order)
                .then((result) => {
                    this.$Notice.success({
                        title: this.$t('Alert'),
                        desc: result.data.message? result.data.message : this.$t('Data Saved Successfully'),
                    });
                    this.resetOrder();
                    $('#sendModal').modal('show');
                    this.lock = false;
                }).catch((err) => {
                    this.$Notice.error({
                        title: this.$t('Alert'),
                        desc: err.response.data.message,
                    });
                    this.lock = false;
                });
            }, 
            
            resetOrder(){
                this.order = {
                    name: "",
                    phone: "",
                    email: "",
                    city_id: "",
                    date: moment().format('YYYY-MM-DD'),
                };
            },
            disabledDates(date) {
                const today = moment().format('YYYY-MM-DD');
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            validateOffer(){
                let rtn = true;
                if(this.order.name.trim() == ""){
                    this.$Notice.error({
                        title: this.$t('Name is required'),
                    });
                    rtn = false;
                }
                if(this.order.city_id == ""){
                    this.$Notice.error({
                        title: this.$t('City is required'),
                    });
                    rtn = false;
                }
                if (this.order.date == null || this.order.date.trim() == "") {
                    this.$Notice.error({
                        title: this.$t('date is required'),
                    });
                    rtn = false;
                }
                if (!(/((\+?966)|05)([0-9]{7,9})$/.test(this.order.phone.trim()))) {
                    this.$Notice.error({
                        title: this.$t(`Phone Format (+9665*******,05********)`),
                    });
                    rtn = false;
                }
                return rtn;
            }
        }
    }
</script>