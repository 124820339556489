<template>
    <Form :route-store="routeStore" />
</template>
<script>
    import Form from './../components/subscribes/Form.vue';
    import moment from 'moment';

export default {
        props: ['routeStore'],
        components: {
            Form
        },
        mounted(){
            this.$root.$on('openModal', ()=> {
                $('#sendModal').modal('show');
            });
        },
    }
</script>