<template>
	<div>
		<form @submit.prevent="sendOrder" method="POST" class="row form">
			<!-- Col -->
			<div class="col-md-10">
				<div class="row">
					<!-- Col -->
					<div class="col-md-4">
						<div class="form-group">
							<input type="text" v-model.trim="order.name" required class="form-control mb-1"
								:placeholder="$t('name') + ' ' + $t('Required')" />

							<input type="text" dir="ltr" required v-model.trim="order.phone" class="form-control mb-1"
								:placeholder="$t('Required') + ' +9665*********'" />
						</div>
					</div>
					<!-- /Col -->

					<!-- Col -->
					<div class="col-md-4">
						<div class="form-group">
							<DatePicker :editable="false" format="YYYY-MM-DD" :disabledDate="disabledDates"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" v-model.trim="order.start_at" placeholder="yyyy-mm-dd" value-type="format"
								class="mb-1" required></DatePicker>

							<DatePicker :editable="false" format="YYYY-MM-DD" :disabledDate="disabledAfterDates"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" v-model.trim="order.back_at" placeholder="yyyy-mm-dd" value-type="format"
								></DatePicker>
						</div>
					</div>
					<!-- /Col -->

					<!-- Col -->
					<div class="col-md-4">
						<div class="form-group">
							<v-select :options="getCities('from')" :reduce="(city) => city.id"
								:placeholder="$t('the city') + ' ' + $t('Required')" v-model="order.from_id"
								:dir="gelang == 'en' ? 'ltr' : 'rtl'" label="titleLang"></v-select>

							<!-- <input type="text" required v-model.trim="order.from_id" class="form-control mb-1"
								:placeholder="$t('the city') + ' ' + $t('Required')" /> -->
						</div>
					</div>
					<!-- /Col -->

				</div>
			</div>
			<!-- /Col -->
			<!-- Col -->
			<div class="col-md-2">
				<button type="submit" :disabled="lock" class="btn btn-filter">
					<template v-if="!lock">
						<span>
							{{ $t("Send") }}
							<!-- <br />
							{{ $t("Request") }} -->
						</span>
					</template>
					<template v-else>
						<spanner :loading="lock"></spanner>
					</template>
				</button>
			</div>
			<!-- /Col -->
		</form>

	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: ["categoryId", "storeRoute", "cities"],
	data() {
		return {
			gelang: window.lang,
			showModal: false,
			lock: false,
			order: {
				name: "",
				phone: "",
				from_id: "",
				start_at: "",
				back_at: "",
				category_id: "",
				type: 'camping'
			},
		};
	},
	computed: {
		adults() {
			return parseInt(this.order.adult) + parseInt(this.order.childs);
		},
	},
	beforeMount() {
		this.order.category_id = this.categoryId;
	},
	methods: {
		getCities(location) {
			if(location == 'from'){
				return this.cities.filter(city => city.id != this.order.to_id);
			}else{
				return this.cities.filter(city => city.id != this.order.from_id);
			}
		},
		sendOrder() {
			this.lock = true;
			if (!this.validateBeforeSubmit()) {
				this.lock = false;
				return;
			}
			axios.post(this.storeRoute, this.order)
				.then((res) => {
					this.$Notice.success({
						title: this.$t("Alert"),
						desc: this.$t("Data Saved Successfully"),
					});
					this.resetOrder();
					this.lock = false;
					this.$root.$emit('openModal');
				})
				.catch((err) => {
					this.$Notice.error({
                        title: this.$t('Alert'),
                        desc: err.response.data.message,
                    });
					this.lock = false;
				});
		},
		resetOrder() {
			this.order = {
				name: "",
				phone: "",
				from_id: "",
				start_at: "",
				back_at: "",
				category_id: "",
				type: 'hotels'
			};
		},
		disabledDates(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			return date < today;
		},
		disabledAfterDates(date) {
			const today = new Date(this.order.start_at) || new Date();
			today.setHours(0, 0, 0, 0);
			return date < today;
		},
		
		validateBeforeSubmit() {
			let rtn = true;
			if (this.order.name.trim() == "") {
				this.$Notice.error({
					title: this.$t("Name is required"),
				});
				rtn = false;
			}
			if (this.order.start_at == null || this.order.start_at.trim() == "") {
				this.$Notice.error({
					title: this.$t("From date is required"),
				});
				rtn = false;
			}

			if (this.order.back_at == null || this.order.back_at.trim() == "") {
				this.$Notice.error({
					title: this.$t("To date is required"),
				});
				rtn = false;
			}

			if (!(/((\+?966)|05)([0-9]{7,9})$/.test(this.order.phone.trim()))) {
				this.$Notice.error({
					title: this.$t(`Phone Format (+9665*******,05********)`),
				});
				rtn = false;
			}

			if (this.order.from_id == null || isNaN(this.order.from_id)) {
				this.$Notice.error({
					title: this.$t("City is required"),
				});
				rtn = false;
			}

			return rtn;
		},
	},
};
</script>
